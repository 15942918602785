<template>
  <v-app id="login" class="indigo darken-4">
    <v-main>
      <v-container id="container" fill-height>
        <v-row align="center" class="mb-16 mb-sm-0">
          <v-col
            v-if="$vuetify.breakpoint.width > 1263"
            cols="7"
            class="white--text"
          >
            <h1 class="text-h1 font-weight-medium">El internet</h1>
            <h2 class="mb-6 text-h2">
              ¡Nos acerca, nos informa y nos <b>transforma!</b>
            </h2>
            <v-btn
              class="text-capitalize"
              rounded
              color="primary"
              large
              dark
              @click="registro()"
            >
              Regístrate
            </v-btn>
          </v-col>

          <v-col cols="12" lg="5" class="text-center">
            <v-card
              elevation="2"
              class="pa-6 rounded-xl login-card mx-auto"
              max-width="550"
              min-width="350"
            >
              <div class="d-flex justify-center align-center mb-2">
                <img src="../../assets/img/LogoSecretariaInnovacion.svg" width="300px" />
              </div>

              <h1 class="text-center blue--text">Iniciar sesión</h1>

              <v-form @submit.prevent="login()">
                <v-card-text>
                  <v-text-field
                    id="email"
                    class="rounded-pill custom-text-field"
                    label="Usuario / Correo electrónico *"
                    prepend-inner-icon="mdi-account"
                    outlined
                    name="login"
                    type="text"
                    @blur="$v.form.email.$touch()"
                    v-model="form.email"
                    :error-messages="emailErrors"
                  >
                  </v-text-field>
                  <v-text-field
                    class="rounded-pill"
                    id="password"
                    prepend-inner-icon="mdi-lock"
                    outlined
                    name="password"
                    v-model="form.password"
                    label="Contraseña *"
                    :type="showPassword ? 'text' : 'password'"
                    @blur="$v.form.password.$touch()"
                    :error-messages="passwordErrors"
                  >
                    <template #append>
                      <v-icon
                        v-if="!showPassword"
                        @click="showPassword = !showPassword"
                        :color="passwordErrors.length > 0 ? 'red' : ''"
                      >
                        mdi-eye
                      </v-icon>
                      <v-icon
                        v-else
                        @click="showPassword = !showPassword"
                        :color="passwordErrors.length > 0 ? 'red' : ''"
                        >mdi-eye-off
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-card-text>

                <v-card-actions class="justify-center text-center flex-column">
                  <v-btn
                    id="btn-iniciar"
                    width="150"
                    class="rounded-pill text-none"
                    color="primary"
                    large
                    dark
                    type="submit"
                    :loading="loading"
                    >Iniciar sesión</v-btn
                  >
                  <v-btn
                    width="150"
                    class="text-capitalize mt-3 mx-0"
                    rounded
                    color="primary"
                    large
                    dark
                    @click="registro()"
                    v-if="$vuetify.breakpoint.width < 1263"
                  >
                    Regístrate
                  </v-btn>

                  <v-row class="my-4">
                    <v-col>
                      <hr width="100" />
                    </v-col>
                    o
                    <v-col>
                      <hr width="100" />
                    </v-col>
                  </v-row>

                  <span class="grey--text">
                    Inicia sesión con Identidad Digital
                  </span>

                  <v-btn
                    class="rounded-pill text-capitalize primary my-2"
                    width="210"
                    large
                    @click="
                      goToIdentidadDigital(
                        `${BASE_URL_ID}/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL.replace(
                          '/',
                          '%2F'
                        )}`
                      )
                    "
                    :loading="loading"
                  >
                    <v-img
                      height="18"
                      width="160"
                      contain
                      src="../../assets/img/Logo_ID.png"
                    />
                  </v-btn>

                  <router-link to="/recuperar-password" class="grey--text"
                    >Recupera tu contraseña</router-link
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer class="footer text-center transparent pt-4" app absolute>
      <v-col
        cols="12"
        sm="4"
        v-show="
          $vuetify.breakpoint.height > 550 && $vuetify.breakpoint.width > 400
        "
      >
        <h4 class="white--text">#ConectandoElSalvador</h4>
      </v-col>
      <v-col cols="12" sm="4">
        <v-img
          max-height="150"
          src="../../assets/img/Logo_GOES_horizontal_L.svg"
          contain
        />
      </v-col>
      <v-col cols="12" sm="4">
        <div class="mx-auto">
          <a href="https://www.facebook.com/PresidenciaSV/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="white"
              class="bi bi-facebook text-white mx-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
              />
            </svg>
          </a>
          <a href="https://twitter.com/PresidenciaSV" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="white"
              class="bi bi-twitter text-white mx-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
              />
            </svg>
          </a>
          <a
            href="https://www.youtube.com/user/casapresidencialsv"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="white"
              class="bi bi-youtube text-white mx-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
              />
            </svg>
          </a>
        </div>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import jwtDecode from "jwt-decode";

export default {
  name: "login",
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  data: () => ({
    form: {
      email: null,
      password: null,
    },
    showPassword: false,
    loading: false,
    REDIRECT_URL: process.env.VUE_APP_REDIRECT_URL,
    BASE_URL_ID: process.env.VUE_APP_BASE_URL_ID,
    CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
  }),
  methods: {
    ...mapActions(["setAuth"]),
    ...mapMutations(["setToken", "setUserInfo"]),
    ...mapMutations("utils", ["setRutas"]),
    async login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const response = await this.services.auth.login(this.form);
          if (response.data.message) {
            this.temporalAlert({
              show: true,
              message: response.data.message,
              type: "success",
            });
            return;
          }
          const { user } = jwtDecode(response.data.token);
          this.setAuth(response?.data);
          if (user?.two_factor_status) {
            this.$router.push({ name: "2fa" });
          } else {
            this.$router.push({ name: "home" });
          }
        } catch (e) {
        } finally {
          this.loading = false;
        }
      }
    },
    async goToIdentidadDigital(url) {
      // console.log(url);
      window.open(url, "_parent");
    },
    async registro() {
      this.$router.push("/registro");
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push("Correo es obligatorio");
      !this.$v.form.email.email && errors.push("El correo no es valido");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required &&
        errors.push("Contraseña es obligatoria");
      return errors;
    },
  },
  async created() {
    this.setRutas([]);
    localStorage.clear();
    if (this.$route?.query?.code) {
      this.mostrarIdentificaciones = false;
      await this.services.auth
        .GetTokenDigitalIdentity({ code: this.$route?.query?.code })
        .then(async (response) => {
          // await this.decodeToken(response?.data?.token)
          await this.setAuth(response?.data);
        })
        .then(() => {
          this.$router.replace("/home");
        })
        .catch(async () => {
          await this.$router.replace("/identidad-digital");
        });
    }
  },
  mounted() {
    const [emailElement, passwordElement] =
      document.getElementsByTagName("label");
    emailElement.classList.add("v-label--active");
    emailElement.style.backgroundColor = "white";
    passwordElement.classList.add("v-label--active");
    passwordElement.style.backgroundColor = "white";
  },
};
</script>

<style scoped>
@media screen and (max-width: 375px) {
  .login-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
  }

  .footer {
    display: none;
  }
}

#login {
  background-image: url("../../assets/img/BGloginWeb.png");
  background-size: cover;
}
</style>
